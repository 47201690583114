export const AP_PROMOTIONS_ADDRESS = "https://promocje.autopartner.com";
export const AP_PROMOTIONS_API_ADDRESS = AP_PROMOTIONS_ADDRESS + "/api.php";
export const AP_PROMOTIONS_AUTH_ADDRESS = AP_PROMOTIONS_ADDRESS + "/index.php";

export const FILIA_ADDRESS = "https://filia.autopartner.com";
export const FILIA_API_ADDRESS = FILIA_ADDRESS + "/api/";
export const AP_PROMOTIONS_NEW_ADDRESS = FILIA_ADDRESS + "/apipp/";

export const APEXPERT_ADDRESS = "https://apexpert.pl";
export const APEXPERT_API_ADDRESS = APEXPERT_ADDRESS + "/api/";

export const VIPTOTRIP_ADDRESS = "https://viptotrip.pl";
export const VIPTOTRIP_API_ADDRESS = VIPTOTRIP_ADDRESS + "/api/filia/";

export const WLM_ADDRESS =
  "https://promocje.autopartner.com/competitions/warsztatowa-liga-mistrzow";
export const WLM_API_ADDRESS = WLM_ADDRESS + "/api/index.php";

export const SPN3_ADDRESS =
  "https://promocje.autopartner.com/competitions/3-skoki-po-nagrody";
export const SPN3_API_ADDRESS = SPN3_ADDRESS + "/api/index.php";

export const MAXGEARSKY_ADDRESS = "https://maxgearsky.autopartner.com";
export const MAXGEARSKY_API_ADDRESS = MAXGEARSKY_ADDRESS + "/api/";

export const lnmwarsztat_ADDRESS =
  "https://promocje.autopartner.com/competitions/laczy-nas-maxgear/api/warsztat/";
export const lnmsklep_ADDRESS =
  "https://promocje.autopartner.com/competitions/laczy-nas-maxgear/api/sklep/";

//export const FILIA_ADDRESS = 'https://platformafilieapi-intranetap.msappproxy.net'
//export const FILIA_API_ADDRESS = FILIA_ADDRESS + '/api/'

//export const FILIA_ADDRESS = 'https://filia.autopartner.com'
//export const FILIA_API_ADDRESS = FILIA_ADDRESS + '/apidemo/'
